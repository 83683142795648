import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
        margin:0;
        padding: 0;
    } 
    body {
        margin: 0;
        padding: 0; 
        font-weight: 400;
    }
`;

export default GlobalStyle;
